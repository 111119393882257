import {onFocus, onInput} from '@github-ui/onfocus'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function searchType(types: {[key: string]: number}): string {
  const params = new URLSearchParams(window.location.search)
  let type = params.get('type') || 'Repositories'
  let max = 0
  for (const key in types) {
    if (types[key]! > max) {
      max = types[key]!
      type = key
    }
  }
  return type
}

function termToken({prefix, value}: {prefix: string; value: string | string[]}): string {
  if (prefix === '') {
    return ''
  }
  if (Array.isArray(value)) {
    const insides = value.map(v => `${prefix}${v}`).join(' OR ')
    return value.length > 1 ? `(${insides})` : insides
  } else if (value) {
    return `${prefix}${value}`
  }
  return ''
}

function buildQuery() {
  const inputs = Array.from(document.querySelectorAll<HTMLInputElement>('input[type=text].js-advanced-search-prefix'))
  const selects = Array.from(document.querySelectorAll<HTMLSelectElement>('select.js-advanced-search-prefix'))
  const checkboxes = Array.from(document.querySelectorAll<HTMLInputElement>('.js-advanced-search-prefix:checked'))

  const searchTerms = [...terms(inputs), ...terms(selects), ...terms(checkboxes)]

  const types = searchTerms.reduce(
    (acc, term) => {
      if (term.value && term.type) {
        acc[term.type]!++
      }
      return acc
    },
    {Repositories: 0, Users: 0, Code: 0, Issues: 0} as Record<string, number>,
  )

  const tokens = searchTerms.reduce((acc, item) => `${acc} ${termToken(item)}`.trim(), '')

  const keywords = document.querySelector<HTMLInputElement>('.js-advanced-search-input')!.value

  document.querySelector<HTMLInputElement>('.js-type-value')!.value = searchType(types)
  document.querySelector<HTMLInputElement>('.js-search-query')!.value = `${keywords} ${tokens}`.trim()

  const text = document.querySelector<HTMLElement>('.js-advanced-query')!
  text.textContent = ''
  text.textContent = tokens

  const span = document.createElement('span')
  span.textContent = keywords.trim()
  text.prepend(span, ' ')
}

function wrap(value: string, globPattern: string | null, regexPattern: string | null): string {
  value = value.trim()
  if (value.search(/\s/g) !== -1) {
    if (regexPattern) {
      value = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      return regexPattern.replace('$0', value)
    }
    return `"${value}"`
  }
  if (globPattern && value) {
    return globPattern.replace('$0', value)
  }
  return value
}

interface Term {
  prefix: string
  value: string | string[]
  type: string | null
}

function terms(inputs: Array<HTMLInputElement | HTMLSelectElement>): Term[] {
  return inputs
    .map(input => {
      const value = input.value.trim()
      const prefix = input.getAttribute('data-search-prefix')!
      const type = input.getAttribute('data-search-type')
      // allows us to create a glob from input eg `ts` + `*.$0` -> `*.ts` (for Blackbird)
      const globPattern = input.getAttribute('data-glob-pattern')
      // Blackbird doesn't let us use spaces in glob patterns so if there's a space we'll use this pattern to make a
      // regular expression if the value has spaces in it eg `foo bar.py` +`path:/(^|\/)$0$/` -> `path:/(^|\/)foo bar\.py$/`
      // (note the escaping of the `.`)
      const regexPattern = input.getAttribute('data-regex-pattern')
      // Blackbird implicitly ANDs its qualifiers together so if you want to search multiple extensions we have to
      // combine them with OR eg (path:*.ts OR path:*.tsx)
      const useOr = input.getAttribute('data-use-or') !== null

      if (prefix === '') {
        return {prefix, value, type}
      } else if (value.search(/,/g) !== -1 && prefix !== 'location') {
        if (useOr) {
          return {
            prefix,
            value: value
              .split(/,/)
              .filter(v => !!v)
              .map(key => wrap(key, globPattern, regexPattern)),
            type,
          }
        } else {
          return value.split(/,/).map(key => ({prefix, value: wrap(key.trim(), globPattern, regexPattern), type}))
        }
      } else {
        return {prefix, value: wrap(value, globPattern, regexPattern), type}
      }
    })
    .flatMap(x => x)
}

onInput('.js-advanced-search-prefix', function () {
  buildQuery()
})

on('change', '.js-advanced-search-prefix', buildQuery)

function handleReasonChanged() {
  const stateEl = document.querySelector<HTMLSelectElement>('#search_state')
  const reasonEl = document.querySelector<HTMLSelectElement>('#search_state_reason')
  // reasonEl is feature flagged and may not be present, this logic only applies when both selects are present
  if (stateEl && reasonEl) {
    const stateValue = stateEl.value
    const reasonValue = reasonEl.value
    if ((reasonValue === 'completed' || reasonValue === 'not planned') && stateValue !== 'closed') {
      stateEl.value = 'closed'
    } else if (reasonValue === 'reopened' && stateValue !== 'open') {
      stateEl.value = 'open'
    }
  }
}

function handleStateChanged() {
  const stateEl = document.querySelector<HTMLSelectElement>('#search_state')
  const reasonEl = document.querySelector<HTMLSelectElement>('#search_state_reason')
  // reasonEl is feature flagged and may not be present, this logic only applies when both selects are present
  if (stateEl && reasonEl) {
    const stateValue = stateEl.value
    const reasonValue = reasonEl.value
    if (stateValue === 'closed' && reasonValue === 'reopened') {
      reasonEl.value = ''
    } else if (stateValue === 'open' && reasonValue !== 'reopened') {
      reasonEl.value = ''
    }
  }
}

on('change', '.js-advanced-search-prefix', el => {
  const prefix = el.currentTarget.getAttribute('data-search-prefix')!
  if (prefix === 'reason:') {
    handleReasonChanged()
  } else if (prefix === 'state:') {
    handleStateChanged()
  }
})

onFocus('.js-advanced-search-input', function (el) {
  const label = el.closest<HTMLElement>('.js-advanced-search-label')!
  label.classList.add('focus')

  el.addEventListener('blur', () => label.classList.remove('focus'), {once: true})
})

observe('.js-advanced-search-input', function () {
  buildQuery()
})
