// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

observe('.js-codesearch-nav', function (nav) {
  const activeNav = nav.querySelector('.selected')
  if (!(activeNav instanceof HTMLElement)) {
    return
  }

  const activeNavLeft = activeNav.offsetLeft
  const activeNavWidth = activeNav.offsetWidth
  const activeNavRight = activeNavLeft + activeNavWidth
  const windowWidth = window.innerWidth
  // Check if active nav is offscreen either on the left or the right
  if (activeNavRight - nav.scrollLeft > windowWidth || activeNavRight < nav.scrollLeft) {
    // Center select nav item on screen
    nav.scrollLeft = activeNavLeft - windowWidth / 2 + activeNavWidth / 2
  }
})

// Fix to only fetch search counts once per type. There are multiple nav bars
// shown at different screen sizes so re-use the counts across each.
observe('.js-codesearch-count', function (countElement) {
  const type = countElement.getAttribute('data-search-type')
  for (const deferredCount of document.querySelectorAll('.js-codesearch-deferred-count')) {
    if (type && deferredCount.getAttribute('data-search-type') === type) {
      const clonedCount = countElement.cloneNode(true)
      if (!(clonedCount instanceof Element)) return
      clonedCount.classList.remove('js-codesearch-count')
      deferredCount.replaceWith(clonedCount)
    }
  }
})
